import tableColumnsCache from "@/utils/tableColumnsCache"

const listVersion = 1
export const listName = 'sl.suporte.project.members'
export const listStorage = tableColumnsCache(listName, [
    {label: 'ID', name: 'id', active: false, sortable: true, ordering: 1},
    {label: 'Nome', name: 'nome', active: true, sortable: true, ordering: 2},
    {label: 'Posição', name: 'position', active: true, sortable: true, ordering: 3},
    {label: 'Situação', name: 'status', active: true, sortable: true, ordering: 3},
], listVersion)

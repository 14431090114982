import http from "@/utils/services/http"

export const listMembers = (project, limit, page, filtros, exportar = false) => {
    let url = `/api/project/${project}/member?page=` + page + '&limit=' + limit + filtros
    let config = {}
    if (exportar){
        config.responseType = 'blob'
    }
    return http.get(url, config)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}

export const findMember = (project, member) => {
    let url = `/api/project/${project}/member/${member}`
    return http.get(url)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}

export const newMember = (project, data) => {
    let url = `/api/project/${project}/member`
    return http.post(url, data)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}

export const updateMember = (project, member, method = 'PUT') => {
    let url = `/api/project/${project}/member/${member.id}`
    let _method = http.put
    if (method === 'PATCH') {
        _method = http.patch
    }
    return _method(url, member)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}

export const deleteMember = (project, member) => {
    let url = `/api/project/${project}/member/${member}`
    return http.delete(url)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}
